import { styled } from '@styles/config'

const Container = styled('div', {
  height: '4px',
  width: '734px',
  minWidth: '400px',
  borderRadius: '4px',
  backgroundColor: '$gray400',
  margin: '0 0 16px 0',
})

const Progress = styled('div', {
  backgroundColor: '$primaryMagenta',
  borderRadius: 'inherit',
  height: '100%',
})

export interface ProgressBar {
  progress: string
}

export const ProgressBar = ({ progress }: ProgressBar) => {
  return (
    <Container>
      <Progress
        css={{
          width: progress,
        }}
      ></Progress>
    </Container>
  )
}
