
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Text } from '@/Introduction/Introduction'
import { Container } from '@/Form/Form'
import type { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'

const NotFound: NextPage = () => {
  const { t } = useTranslation('common')

  return (
    <Container size={'small'}>
      <Text>
        <Trans i18nKey='common:not-found' components={{ br: <br /> }} />
      </Text>
    </Container>
  )
}

export default NotFound


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  