import { Card } from '@/Card/Card'
import { ProgressBar } from '@/ProgressBar/ProgressBar'
import { styled } from '@styles/config'
import { observer } from 'mobx-react'
import React from 'react'

export const Container = styled(Card, {
  alignItems: 'center',
  width: '784px',
  padding: '24px',

  variants: {
    size: {
      small: {
        height: '500px',
      },
      medium: {
        height: '600px',
      },
      large: {
        height: '800px',
      },
    },
  },
})

export const StepTitle = styled('span', {
  color: '$gray800',
  fontSize: '$medium',
  fontWeight: '600',
  opacity: '80%',
  marginBottom: '40px',
})

export interface Form {
  stepTitle: string
  progress?: string
  size: 'small' | 'medium' | 'large'
  children: React.ReactNode | React.ReactNode[]
}

export const Form = observer(({ stepTitle, children, progress, size }: Form) => {
  return (
    <Container size={size}>
      <ProgressBar progress={progress || '0%'}></ProgressBar>
      <StepTitle>{stepTitle}</StepTitle>
      {children}
    </Container>
  )
})
